import {selectById} from "../../../Common/Selector/SelectById";
import {OurServiceMenuDropDown} from "./OurServiceMenuDropDown";
import {DropdownCustomerMenu} from "./DropdownCustomerMenu";
import {OnPageCompletelyLoaded} from "../../../Common/Actions/OnPageCompletelyLoaded";

export class PraticheBarAnimation {

    private praticheBarHtmlElement: HTMLElement;
    private praticheBarHeightPx: number;
    private timeoutName = null;

    constructor() {

        OnPageCompletelyLoaded(() => {
            this.praticheBarHtmlElement = selectById('praticheBar');

            if ( this.praticheBarHtmlElement !== null ) {

                this.praticheBarHeightPx = this.praticheBarHtmlElement.scrollHeight;
                this.addEventListenerOnScroll();
                new OurServiceMenuDropDown();
                new DropdownCustomerMenu();
            }
        })
    }

    public addEventListenerOnScroll(): void {

        document.addEventListener('scroll', () => {

            if ( window.scrollY > this.praticheBarHeightPx ){

                this.animationDropDown();

            } else if ( window.scrollY === 0 ){

                this.toBeFreePraticheBar();
            }

        });
    }

    private fixedPraticheBar(): void {
        this.praticheBarHtmlElement.classList.add('fixed-top');
        this.praticheBarHtmlElement.classList.remove('praticheBarPreAnimation');
        this.praticheBarHtmlElement.style.removeProperty('top');
    }

    private toBeFreePraticheBar(): void {
        this.praticheBarHtmlElement.classList.add('praticheBarPreAnimation');
        this.praticheBarHtmlElement.classList.remove('fixed-top');
        this.praticheBarHtmlElement.style.removeProperty('top');
    }

    private animationDropDown(): void {

        if ( ! this.praticheBarHtmlElement.classList.contains('fixed-top') && window.innerWidth >= 768 ) {

            if ( this.timeoutName === null ) {
                this.timeoutName = setTimeout(() => {

                    let i = this.praticheBarHeightPx;

                    let intervalName = setInterval(() => {
                        i--;

                        if (i == 0) {

                            this.timeoutName = null;
                            clearInterval(intervalName);
                            this.fixedPraticheBar();
                        } else {

                            this.praticheBarHtmlElement.style.top = (window.scrollY - i) + "px";
                        }

                    }, 1);

                }, 250);
            }

        }
    }
}
