import {selectById} from "../../../Common/Selector/SelectById";
const bootstrap = window.bootstrap as any;

export class DropdownCustomerMenu {

    private readonly dropDownCustomerMenuBtn: HTMLElement;
    private dropdown: bootstrap.Dropdown;
    private dropDownMenuVisible: boolean = false;

    constructor() {

        this.dropDownCustomerMenuBtn = selectById('dropDownCustomerMenu');

        if ( this.dropDownCustomerMenuBtn !== null ) {

            this.dropdown = new bootstrap.Dropdown(this.dropDownCustomerMenuBtn);
            this.toggleDropdown();
        }
    }

    public toggleDropdown(): void
    {
        this.dropDownCustomerMenuBtn.addEventListener('click', () => {

            if ( this.dropDownMenuVisible ) {
                this.dropdown.hide();
            } else {
                this.dropdown.show();
            }

            this.dropDownMenuVisible = !this.dropDownMenuVisible;
        });
    }
}
new DropdownCustomerMenu();
