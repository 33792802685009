import {selectById} from "../../../Common/Selector/SelectById";
import {selectByClass} from "../../../Common/Selector/SelectByClass";

class ToastComponentAnimation {

    private toastId: string;
    private readonly autoStartDelay: number;
    private readonly autoStopAfter: number;
    private readonly showAnimationClass: string[] = [];
    private readonly hideAnimationClass: string;

    public constructor(toastId: string, autoStartDelay: number, autoStopAfter: number, showAnimationClass: string, hideAnimationClass: string) {

        this.toastId = toastId;
        this.autoStartDelay = autoStartDelay;
        this.autoStopAfter = autoStopAfter;
        this.showAnimationClass = showAnimationClass.split(", ");
        this.hideAnimationClass = hideAnimationClass;
    }

    public autoStart(): void
    {
        if ( this.autoStartDelay > 0 ) {

            setTimeout(() => {

                this.showAnimationClass.map((className: string) => {
                    selectById(this.toastId).classList.add(className);
                });

            }, this.autoStartDelay)
        }
    }

    public autoStop(): void
    {
        const toastElement = selectById(this.toastId);

        if ( this.autoStopAfter > 0 ) {

            setTimeout(() => {

                toastElement.classList.add(this.hideAnimationClass);

                setTimeout(() => {
                    this.showAnimationClass.map((className: string) => {
                        toastElement.classList.remove(className);
                    });
                }, 1000);

            }, this.autoStopAfter)
        }
    }
}

export function allToastComponentLoader() {

    const scriptLoadToastList = selectByClass('script_load_toast_component');

    for (let scriptLoadToast of scriptLoadToastList) {

        const toastId: string = scriptLoadToast.getAttribute('id');
        const autoStartDelay: number = parseInt(scriptLoadToast.getAttribute('data-autoStartDelay'));
        const autoStopAfter: number = parseInt(scriptLoadToast.getAttribute('data-autoStopAfter'));
        const showAnimationClass: string = scriptLoadToast.getAttribute('data-showAnimationClass');
        const hideAnimationClass: string = scriptLoadToast.getAttribute('data-hideAnimationClass');

        const toastComponentAnimation = new ToastComponentAnimation(toastId, autoStartDelay, autoStopAfter, showAnimationClass, hideAnimationClass);
        toastComponentAnimation.autoStart();
        toastComponentAnimation.autoStop();
    }
}
