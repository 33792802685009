import {selectById} from "../../../Common/Selector/SelectById";
const bootstrap = window.bootstrap as any;

export class OurServiceMenuDropDown {

    private readonly praticheServiceDropdown: HTMLButtonElement;
    private readonly praticheServiceDropdownMenu: HTMLElement;
    private bsDropDown: bootstrap.Dropdown;
    private isPraticheServiceDropDownMenuVisible: boolean = false;

    constructor() {

        this.praticheServiceDropdown = <HTMLButtonElement>selectById('praticheServiceDropdown');
        this.praticheServiceDropdownMenu = selectById('praticheServiceDropdownMenu');

        if ( this.praticheServiceDropdown && this.praticheServiceDropdownMenu ) {

            // this.bsDropDown = new bootstrap.Dropdown(this.praticheServiceDropdownMenu, {
            //     autoClose: true
            // });
            //
            // this.praticheServiceDropdown.addEventListener('click', () => {
            //     this.toggle();
            // });

            this.toggleOfficeInfo();
        }
    }

    public toggle() {

        console.log('triggered');

        if ( this.praticheServiceDropdown && this.praticheServiceDropdownMenu ) {

            if (this.isPraticheServiceDropDownMenuVisible) {

                this.bsDropDown.hide();
                this.praticheServiceDropdown.classList.remove('underline-orange');
            } else {

                this.bsDropDown.show();

                document.addEventListener('click', (event:MouseEvent) => {

                    if (this.isPraticheServiceDropDownMenuVisible) {

                        let targetEvent = event.target;

                        if ( targetEvent != this.praticheServiceDropdownMenu ) {
                            this.toggle();
                        }
                    }
                }, {once: true, capture: true});

                this.praticheServiceDropdown.classList.add('underline-orange');
            }

            this.isPraticheServiceDropDownMenuVisible = !this.isPraticheServiceDropDownMenuVisible;
        }
    }

    public toggleOfficeInfo(): void|boolean {

        const dataActiveOfficeIdHover = document.querySelector('[data-active-office-id-hover]');

        // all'ingresso in qualsiasi voce del menu rimuovi qualsiasi class .active
        selectById('praticheServiceDropdownMenu').addEventListener('mouseenter', () => {
            this.removeActiveOnEnterInDropDown();
        });

        document.querySelectorAll('[data-office-id]').forEach(element => {

            const officeId = element.getAttribute('data-office-id');

            if ( ! officeId ) {
                console.trace(officeId);
                return false;
            }

            element.addEventListener('mouseover', () => {

                dataActiveOfficeIdHover.setAttribute('data-active-office-id-hover', officeId);

                // title
                this.resetDisplayNoneOnAllOfficeTitle();
                this.showOfficeTitleByOfficeId(officeId);

                // services
                this.resetDisplayNoneOnAllOfficeDescriptionList();
                this.showOfficeServiceListByOfficeId(officeId);
            });

            element.addEventListener('click', () => {

                this.removeActiveOnEnterInDropDown();

                element.classList.add('active');

                // title
                this.resetDisplayNoneOnAllOfficeTitle();
                this.showOfficeTitleByOfficeId(officeId);

                // services
                this.resetDisplayNoneOnAllOfficeDescriptionList();
                this.showOfficeServiceListByOfficeId(officeId);
            });
        });

        if ( ! dataActiveOfficeIdHover ) {
            console.trace(dataActiveOfficeIdHover);
            return false;
        }

        this.onMouseEnterOnActiveOffice(dataActiveOfficeIdHover);

        this.onMouseLeaveOnActiveOffice(dataActiveOfficeIdHover)
    }

    private removeActiveOnEnterInDropDown(): void
    {
        document.querySelectorAll('[data-office-id]').forEach(element => {
            element.classList.remove('active');
        });
    }

    private onMouseEnterOnActiveOffice(dataActiveOfficeIdHover): void
    {
        dataActiveOfficeIdHover.addEventListener('mouseenter', () => {

            const officeId = dataActiveOfficeIdHover.getAttribute('data-active-office-id-hover');

            if ( ! officeId ) {
                console.trace(officeId);
                return false;
            }

            document.querySelector('[data-office-id="'+officeId+'"]').classList.add('active');
        });
    }

    private onMouseLeaveOnActiveOffice(dataActiveOfficeIdHover): void
    {
        dataActiveOfficeIdHover.addEventListener('mouseleave', () => {

            const officeId = dataActiveOfficeIdHover.getAttribute('data-active-office-id-hover');

            if ( ! officeId ) {
                console.trace(officeId);
                return false;
            }

            document.querySelector('[data-office-id="'+officeId+'"]').classList.remove('active');
        });
    }

    private showOfficeTitleByOfficeId(officeId: string): void
    {
        selectById('data-office-id-'+officeId).classList.remove('d-none');
    }

    private showOfficeServiceListByOfficeId(officeId: string): void
    {
        document.querySelectorAll('.data-office-description-id-'+officeId).forEach(element => {
            element.classList.remove('d-none');
        });
    }

    private resetDisplayNoneOnAllOfficeTitle(): void {
        document.querySelectorAll('.office-title-list').forEach(officeTitleList => {
            if ( ! officeTitleList.classList.contains('d-none') ) {
                officeTitleList.classList.add('d-none');
            }
        });
    }

    private resetDisplayNoneOnAllOfficeDescriptionList(): void
    {
        document.querySelectorAll('.office-description-list').forEach(officeTitleList => {
            if ( ! officeTitleList.classList.contains('d-none') ) {
                officeTitleList.classList.add('d-none');
            }
        });
    }
}
