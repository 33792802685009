import {selectById} from "../../../Common/Selector/SelectById";

export function goUpButtonScroll() {
    const btn = selectById('goUpButton');

    if ( btn ) {

        btn.addEventListener('click', () => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        });
    }
}
